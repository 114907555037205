<template>
  <div class="cw-page">
    <cw-hero
      v-show="!isMobile"
      :image="heroImage"
      show-on-mobile
    >
      <template #hero-title>
        <slot name="hero-title"/>
      </template>

      <template #hero-text>
        <slot name="hero-text"/>
      </template>
    </cw-hero>

    <div class="cw-page__alerts">
      <slot name="alert"/>
    </div>

    <v-row
      align="start"
      class="cw-page__title"
    >
      <v-col
        class="pr-4"
        cols="auto"
      >
        <v-icon class="cw-page__icon mt-1">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col class="pl-0">
        <h1 class="text-h5">
          {{ title }}
        </h1>
      </v-col>
    </v-row>

    <v-container
      class="cw-page__content pa-0"
      fluid
    >
      <slot/>
    </v-container>

    <cw-high-cost-warning v-if="country === 'se'"/>

    <v-row no-gutters>
      <v-col
        class="cw-page__actions"
      >
        <slot name="actions">
          <v-spacer/>
          <v-btn
            id="submit"
            :href="`https://www.saldo.com/${locale}/`"
            dark
            color="purple-one darken-2"
            @click="$eventLogger.clickEvent($event)"
          >
            <translate>
              Close
            </translate>
          </v-btn>
        </slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwPage',

  components: {
    'cw-high-cost-warning': () => import('@se/components/HighCostWarning'),
  },

  props: {
    heroImage: {
      type: String,
      default: () => null,
    },

    icon: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters({
      country: 'application/country',
      language: 'application/language',
      product: 'application/getProduct',
      title: 'dataLayer/title',
      isMobile: 'getMobile',
    }),

    locale() {
      return `${this.country}-${this.language}`;
    },
  },
};
</script>

<style lang="scss">
.cw-page {
  &__alerts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 16px;
  }

  &__title {
    hyphens: auto;
  }

  &__actions {
    display: flex;
    padding: 16px !important;

    @include mq($from: xs) {
      padding: 24px !important;
    }

    @include mq($from: md) {
      padding: 32px !important;
    }
  }
}
</style>
